// Bootstrap
@use 'bootstrap/scss/bootstrap' as bootstrap;
@use "sass:color";

// Noty for Vue
@use 'vuejs-noty/dist/vuejs-noty.css';
// Fonts
@use 'quill';
@use 'timeicon';
$animationDuration: 0.5s;
@use 'vue2-animate/dist/vue2-animate.min.css';
// Variables
@use 'variables' as vars;
// Element UI
@use 'elementui/index.css';
@use 'bootstrap-select/sass/bootstrap-select';
// Vue-Select
$vs-component-placeholder-color: vars.$brand-rockblue;
$vs-state-active-bg: vars.$brand-primary;
$vs-border-color: #ced4da;
$vs-controls-color: vars.$brand-rockblue;
@use "vue-select/src/scss/vue-select";
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}

@media print {
    tr:nth-child(odd) td {
        background-color: #ededea !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact;
    }
    .isspecial {
        background-color: #FFDBC8 !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact;
    }
    .isweekend {
        background-color: #FFDBC8 !important;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact;
    }
}

// Element
.el-select .el-input.is-focus .el-input__inner {
    font-size: 16px !important;
}

.el-select-group__wrap {
    margin-top: 14px;
}

.el-input__inner {
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.el-select .el-input.is-focus .el-input__inner {
    color: #495057;
    background-color: #fff;
    border-color: #ffc1a1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 110, 33, 0.25);
}

.websitebody {
    font-size: 20px;
    line-height: 1.7;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1480px;
    }
}

.pt-12p {
    padding-top: 12px;
}

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

h1,
h2,
h3 {
    margin-bottom: 30px;
    letter-spacing: -.5px;
    font-weight: 700;
}

h1 {
    font-size: 30px;
    letter-spacing: -1px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 16px;
    color: vars.$gray;
    padding-top: 16px;
    font-weight: 700;;
}

h5 {
    font-size: 12px;
    color: vars.$gray-light;
    text-transform: uppercase;
    padding-top: 12px;
}

.mh-250 {
    min-height: 250px;
}

.rockblue {
    color: vars.$brand-rockblue;
}

.bg-rockblue {
    background-color: #F0F4F8;
}

.text-normal {
    color: vars.$text-color;
}

.fw-700 {
    font-weight: 700;
}

.subhead {
    margin-top: -20px;
    margin-bottom: 32px;
}

.subhead-sm {
    font-size: 14px;
}

.atl-max-width {
    max-width: 790px;
}

.atl-info-box {
    background: #F0F4F8;
    border-radius: bootstrap.$border-radius;
    padding: 24px;
    &_icon {
        width: 30px;
        float: left;
        &--svg {
            width: 24px;
            height: 24px;
            stroke: #9FB3C8;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
        }
    }
    &_text {
        color: #334E68;
        line-height: 1.8;
    }
}

.atl-footer {
    text-align: right;
    font-size: 12px;
    color: vars.$gray;
    margin-top: 50px;
    a {
        color: vars.$gray;
    }
}

.button {
    &--feathericon {
        width: 16px;
        height: 16px;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
    }
    &--feathericon-2 {
        width: 16px;
        height: 16px;
        stroke: #fff;
        stroke-width: 3;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        padding-bottom: 3px;
        margin-right: 4px;
    }
}

.text-icon {
    width: 16px;
    height: 16px;
    stroke: vars.$text-color;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    padding-bottom: 3px;
    margin-right: 4px;
}

.icon-orange {
    stroke: vars.$brand-primary;
}

.icon-green {
    stroke: vars.$brand-success;
}

.link-icon {
    width: 16px;
    height: 16px;
    stroke: vars.$link-color;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    padding-bottom: 3px;
    margin-right: 4px;
}

#mobile-backend-nav {
    display: none;
    // @media (max-width: 991.98px) {
    @media (max-width: 1199px) {
        display: block;
    }
}

#backend-nav {
    margin-top: 71px;
    @media (max-width: 767.98px) {
        margin-top: 12px;
    }
    .nav-group-title {
        text-transform: uppercase;
        padding-bottom: 12px;
        padding-left: 16px;
        font-size: 14px;
        color: vars.$brand-rockblue;
        font-weight: 500;
    }
    a {
        text-decoration: none;
        line-height: 24px;
    }
    .nav-link {
        &--icon {
            width: 16px;
            height: 16px;
            stroke: vars.$gray;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
            margin-right: 6px;
        }
    }
    .nav-link--icon.active {
        width: 16px;
        height: 16px;
        stroke: #fff;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
        margin-right: 6px;
    }
    .nav-icon-protected {
        stroke: #D4AF37;
    }
}

.btn {
    -webkit-box-shadow: 6px 6px 25px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 6px 6px 25px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 6px 6px 25px 0px rgba(0, 0, 0, 0.16);
}

.btn-default,
.btn-default:hover {
    box-shadow: none;
    font-size: bootstrap.$font-size-sm;
}

// btn-outline-primary styles are defined in the Bootstrap overrides section at the top of this file.

.btn-info {
    background: vars.$brand-info;
    border: vars.$brand-info;
    &:hover {
        background: vars.$text-color;
        border: vars.$text-color;
    }
}

.btn-link {
    border: none;
    text-decoration: underline;
    box-shadow: none;
    font-size: 16px;
}

ul#atl-main-nav {
    margin-left: 0;
    padding-left: 0;
    a.nav-link {
        color: vars.$text-color;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        padding-left: 0;
    }
    a.active {
        color: vars.$brand-primary;;
        background:linear-gradient(180deg, transparent 60%, rgba(vars.$brand-primary, 0.1) 60%);
    }

    a:hover {
        color: vars.$brand-primary;
    }
}

#atl-mobile-nav {
    padding: 20px 16px;
    border-radius: 6px;
    width: 100%;
    background-color: vars.$brand-primary;
    a {
        border-bottom: 1px solid vars.$brand-primary;
        line-height: 1.8rem;
        color: #fff;
        font-weight: 500;
    }
    a:hover {
        background-color: #fff;
        color: vars.$text-color;
    }
}

.nav-pills>.active>a,
.nav-pills>.active>a:hover {
    background-color: vars.$text-color;
    color: #fff;
    cursor: pointer;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: vars.$text-color;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link:hover {
    background-color: vars.$text-color;
    color: #fff !important;
    cursor: pointer;
}

.nav-pills .nav-link {
    color: vars.$text-color;
    text-transform: uppercase;
    letter-spacing: 0.1;
    font-size: 14px;
    text-decoration: underline;
}

p a {
    text-decoration: underline;
    // border-bottom: 1px solid $brand-primary;
    // padding-bottom: 3px;
    -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1), background-color .2s cubic-bezier(.645, .045, .355, 1);
    transition: color .2s cubic-bezier(.645, .045, .355, 1), background-color .2s cubic-bezier(.645, .045, .355, 1);
}

p a btn {
    text-decoration: none;
}

.wesupport {
    font-size: 18px;
    color: vars.$gray-light;
    line-height: 24px;
    &--icon {
        width: 14px;
        height: 14px;
        stroke: vars.$brand-primary;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: none;
    }
}

.form-group {
    margin-bottom: 24px;
}

label {
    font-size: 14px;
}

.tab-pane {
    background-color: lighten($color: vars.$text-color, $amount: 80);
    padding: 12px;
    border-radius: 0.25rem;
}


/**
/**  This is for the Avatars
**/

.circle {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background-color: transparent !important;
    z-index: 999;
}

a.circle {
    color: transparent;
}


/* IE fix: removes blue border */

.list-group-item {
    background-color: transparent !important;
}

#team-list {
    .personalpicture {
        width: 250px;
        height: 250px;
    }
    .team-member {
        margin-bottom: 80px;
    }
    .personname {
        font-weight: bold;
    }
    .title {
        // font-weight: bold;
        color: vars.$gray-light;
        margin-top: 4px;
    }
    .bio {
        font-style: italic;
        ul {
            li.list-group-item {
                font-size: 18px;
                padding: 6px;
            }
        }
    }
}

.card {
    // box-shadow: 0 30px 60px -12px rgba(50, 50, 93, .10), 0 18px 36px -18px rgba(0, 0, 0, .15), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
    // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    border: none;
    color: vars.$text-color;
}

.card-header {
    background: #fff;
    border-bottom: 0;
    text-align: right;
    text-transform: uppercase;
    font-weight: 500;
    color: vars.$brand-primary;
    letter-spacing: 0.1rem;
}

.alert-purple {
    border-color: #694D9F;
    background: #694D9F;
    color: #fff;
}

.alert-info {
    border-color: #B4E1E4;
    background: #81c7e1;
    color: #fff;
}

.alert-danger {
    border-color: #B63E5A;
    background: #E26868;
    color: #fff;
}

.alert-warning {
    border-color: #F3F3EB;
    background: #E9CEAC;
    color: #fff;
}

.alert-success {
    border-color: #19B99A;
    background: #20A286;
    color: #fff;
}

.glyphicon {
    margin-right: 10px;
}

.alert a {
    color: gold;
}

.fs12 {
    font-size: 12px;
}

.fs14 {
    font-size: 14px;
}

.fs18 {
    font-size: 18px;
}

.fs20 {
    font-size: 20px;
}

.fs28 {
    font-size: 28px;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

#nprogress .bar {
    background: vars.$brand-primary !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px vars.$brand-primary, 0 0 5px vars.$brand-primary !important;
}

.sweet-modal .sweet-title>h2 {
    padding-top: 20px !important;
}

.el-dropdown-link {
    cursor: pointer;
    color: vars.$text-color;
    font-weight: bold;
}

.el-dropdown-menu__item {
    a:hover {
        text-decoration: none;
        color: vars.$text-color;
    }
    a {
        color: #868e96;
        font-size: 14px;
        line-height: 24px;
        -webkit-transition: color .2s cubic-bezier(.645, .045, .355, 1), background-color .2s cubic-bezier(.645, .045, .355, 1);
        transition: color .2s cubic-bezier(.645, .045, .355, 1), background-color .2s cubic-bezier(.645, .045, .355, 1);
    }
}

.el-icon-arrow-down {
    font-size: 12px;
}

.form-panel {
    background: #fff;
    padding: 1rem;
    border-radius: bootstrap.$border-radius;
}

.border-bottom-primary {
    border-bottom: #FF6E21 1px dotted;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-width: 2px;
}

.badge-secondary {
    background-color: vars.$brand-rockblue;
}

.action-icon {
    cursor: pointer;
}

a .action-icon {
    color: vars.$brand-rockblue;
}

.action-icon:hover {
    color: vars.$text-color;
}


/** Element Styles overwrite */

.el-message-box {
    padding: 12px;
}

.el-message-box__content {
    padding-top: 18px;
    padding-bottom: 18px;
}

.el-message-box__btns {
    padding-bottom: 12px;
}

.el-button--default {
    border: 0;
    background-color: vars.$brand-rockblue;
    color: #fff;
}

.el-button--primary {
    border: 0;
    background-color: vars.$brand-primary;
    color: #fff;
}

.text-striked {
    text-decoration: line-through;
}

.quick-action {
    text-align: center;
    color: #6c757d;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    &:hover {
        cursor: pointer;
        color: #fff;
        background-color: #FF6E21;
        border-radius: 6px;
        .text-danger,
        .text-muted {
            color: #fff !important;
        }
    }
}

.quick-action--icon {
    width: 20px;
    height: 20px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    margin-bottom: 12px;
    &:hover {
        stroke: #fff;
    }
}

.btn--icon {
    width: 20px;
    height: 20px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    cursor: pointer;
    &:hover {
        stroke: #fff;
    }
}

// Fix for printing the table header on each page
@page {
    size: A4 landscape;
    /* auto is the initial value */
    margin: 2mm 4mm 0mm 0mm;
    /* this affects the margin in the printer settings */
}

thead {
    display: table-header-group;
}

tfoot {
    display: table-header-group;
}

@media print {
    table {
        page-break-inside: avoid;
    }
    .overflow-scroll {
        overflow: visible;
    }
}

.clickable {
    cursor: pointer !important;
}

.shadow-md {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 8px -5px rgba(0, 0, 0, 0.03);
}

.toolbar {
    height: 54px;
    vertical-align: middle;
    padding-top: 12px;
    border-radius: 6px;
    padding-right: 12px;
    padding-left: 12px;
    background: #efefef;
    .el-switch {
        margin-top: 5px;
    }
    .el-switch__label {
        margin-top: 6px;
    }
}

.stats-icon {
    width: 3rem;
    height: 3rem;
}

.stats-link {
    color: #6c757d;
    text-decoration: none;
}

.icon-shape {
    padding: 12px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.navbar-nav .nav-link {
    padding-left: 8px;
}

.w-100p {
    width: 100%;
}

.el-table .highlight-row td {
    background: #FFDBC8 !important;
}

.highlight-row td {
    background: #FFDBC8 !important;
}

.el-table .highlight-row-report td {
    background: #efefef !important;
}

.el-select .el-input .el-select__caret {
    color: red;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid vars.$brand-rockblue;
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .25em;
}

.el-divider__text {
    text-transform: uppercase;
    color: vars.$brand-rockblue;
}

.confirm-button {
    border: 2px solid vars.$brand-success;
    color: vars.$brand-success;
    &:hover {
        background-color: vars.$brand-success;
        color: #fff;
    }
}

.table thead th {
    font-size: 14px;
    color: #333;
    border-top: 0;
}

.pt-40p {
    padding-top: 40px;
}

.border-bottom-light {
    border-bottom: 1px solid #efefef;
}

#duties-table tr {
    line-height: 23px;
}

::-moz-selection {
    background: yellow;
}

::selection {
    background: yellow;
}

.text-white {
    color: #fff;
}

.font-bold {
    font-weight: 700;
}

.file__link {
    border: 2px solid #efefef;
    border-radius: 8px;
    padding: 12px 18px;
    width: fit-content;
    // box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.1), 0 18px 36px -18px rgba(0, 0, 0, 0.15), 0 -12px 36px -8px rgba(0, 0, 0, 0.025)
}

.file__link a {
    color: #333;
    font-weight: 600;
    font-size: 14px;
}

.file__link-size {
    font-size: 12px;
    font-weight: normal;
}

.file__link-icon {
    width: 16px;
    height: 16px;
    stroke: #9fb3c8;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    margin-right: 14px;
}

.clickable {
    cursor: pointer;
}

.kpiValue {
    font-size: 24px;
    font-weight: bold;
}

.notes {
    background-color: #FFFF99;
    padding: 6px 10px;
    border-radius: 8px;
    width: fit-content;
}

.table-icon-blade {
    width: 18px;
height: 18px;
stroke: #102b43;
stroke-width: 2;
stroke-linecap: round;
stroke-linejoin: round;
fill: none;
cursor: pointer;
margin-left: 12px;
}

.table-icon-blade:hover {
    cursor:pointer !important;
}

// Override Bootstrap theme colors with our brand colors
.btn-primary {
    background-color: #FF6E21;
    border-color: #FF6E21;

    &:hover, &:focus, &:active, &.active {
        background-color: darken(#FF6E21, 10%);
        border-color: darken(#FF6E21, 10%);
    }
}

// Additional overrides for consistent branding
.btn-outline-primary {
    color: #FF6E21 !important;
    border-color: #FF6E21 !important;
    background-color: transparent !important;
    box-shadow: none;

    &:hover, &:focus, &:active, &.active {
        color: #fff !important;
        background-color: #FF6E21 !important;
        border-color: #FF6E21 !important;
    }
}

// Ensure links and focus elements use the brand color
a {
    color: #FF6E21;

    &:hover {
        color: darken(#FF6E21, 10%);
    }
}

.page-item.active .page-link {
    background-color: #FF6E21;
    border-color: #FF6E21;
}

.page-link {
    color: #FF6E21;

    &:hover {
        color: darken(#FF6E21, 10%);
    }
}

// Custom form controls with brand color
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: #FF6E21;
    border-color: #FF6E21;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #FF6E21;
}

// Override .text-primary class to use brand orange
.text-primary {
    color: vars.$brand-primary !important;
}
