@use "sass:color";

// Body
$body-bg: #f8fafc;

//
// Variables
// --------------------------------------------------


//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            color.scale($gray-base, $lightness: 13.5%) !default; // #222
$gray-dark:              color.scale($gray-base, $lightness: 20%) !default;   // #333
$gray:                   color.scale($gray-base, $lightness: 33.5%) !default; // #555
$gray-light:             color.scale($gray-base, $lightness: 46.7%) !default; // #777
$gray-lighter:           color.scale($gray-base, $lightness: 93.5%) !default; // #eee

$brand-primary:         #FF6E21 !default;
$brand-success:         #5cb85c !default;
$brand-info:            #9FB3C8 !default;
$brand-warning:         #edd967 !default;
// $brand-warning:         #f0ad4e !default;
$brand-danger:          #363232 !default;

$brand-blue:            #334E68;
$brand-rockblue:        #9FB3C8;


$theme-colors: (
  "primary": #FF6E21,
  "warning": #edd967,
  "danger": #FE6F5E
);


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color: #102B43;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `color.scale()` function.
$link-hover-color:      color.scale($link-color, $lightness: -15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Rubik", sans-serif;
$font-family-serif:       Georgia, "Times New Roman", Times, serif !default;

$font-size-h1:            32px; // floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2:            24px; // floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3:            16px; // ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4:            14px; // ceil(($font-size-base * 1.25)) !default; // ~18px

//** By default, this inherits from the `<body>`.
$headings-font-family:    'Montserrat', sans-serif !default;
$headings-font-weight:    700 !default;
$headings-line-height:    1.1 !default;
$headings-color:          $brand-primary;

$custom-file-text: (
  en: "Browse",
  de: "Durchsuchen"
);
